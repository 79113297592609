import "webcrypto-shim";

export let cryptoService: Crypto;
export let CryptoWorker: ServiceWorker;

export type CryptoServices = {
  service: Crypto;
  worker: ServiceWorker;
};

export function configureCrypto(): CryptoServices | undefined {
  return {
    service: window.crypto,
    worker: new Worker(
      new URL("core/model/crypto/crypto.worker", import.meta.url),
    ) as unknown as ServiceWorker,
  };
}

export const setCryptoConfig = (
  newCryptoConfig: CryptoServices | undefined,
): void => {
  if (!newCryptoConfig) return;

  cryptoService = newCryptoConfig.service;
  CryptoWorker = newCryptoConfig.worker;
};
